<template>
  <div class="box">
    <div class="login">
      <p class="title">视力档案后台管理系统</p>
      <!-- <p class="title">学生健康管理系统</p> -->
      <el-form
        :model="userInfo"
        :rules="rules"
        ref="loginForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="username">
          <el-input
            prefix-icon="el-icon-user"
            v-model="userInfo.username"
            maxlength="30"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            prefix-icon="el-icon-lock"
            show-password
            placeholder="请输入密码"
            maxlength="50"
            minlength="6"
            @keyup.enter.native="login"
            v-model="userInfo.password"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login" :loading="isloading"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <ul class="bg-bubbles">
      <li v-for="i in 30" :key="i"></li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      isloading: false,
      loginNum: 0, // 0:未登录 1:登录中 2:已登录
    };
  },
  created() {
    this.loginNum = 0;
    var _this = this;
    document.onkeydown = function (e) {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13 && _this.loginNum == 0) {
        _this.login();
      }
    };
  },
  methods: {
    arrayToTree(items) {
      const result = []; // 存放结果集
      const itemMap = {}; //

      // 先转成map存储
      for (const item of items) {
        itemMap[item.id] = {
          ...item,
          children: [],
        };
      }

      for (const item of items) {
        const id = item.id;
        const pid = item.pid;
        const treeItem = itemMap[id];
        if (pid === 0) {
          result.push(treeItem);
        } else {
          if (!itemMap[pid]) {
            itemMap[pid] = {
              children: [],
            };
          }
          itemMap[pid].children.push(treeItem);
        }
      }
      return result;
    },
    login() {
      if (this.loginNum == 1 || this.loginNum == 2) return false;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loginNum = 1; // 登陆中
          this.isloading = true;
          let data = {
            name: this.userInfo.username,
            pwd: this.userInfo.password,
          };
          this.$api.login.login(data).then((res) => {
            if (res.status == 1) {
              this.loginNum = 2; // 登录成功
              // let datas = this.$table2Tree(res.obj)
              let datas = this.arrayToTree(res.obj);
              console.log("login=>",datas)
              this.isloading = false;
              this.userInfo.id = 
              this.$store.commit("setmyMenu", datas);
              localStorage.setItem("menus", JSON.stringify(datas)); //存储菜单导航
              sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo)); //存储用户登录信息
              sessionStorage.setItem("token", res.msg); //存储token
              // 登录成功之后跳转主页
              if(res.type){
                this.$router.replace("/managementhome");

              }else{
                this.$router.replace("/");
              }
              this.$store.commit("getHeaderPaths", "");
              this.$notify.success({
                title: "success",
                message: "登录成功",
                showClose: false,
                duration: 2000,
              });
              // 登录成功获取页面表格size
              let size = localStorage.getItem("tabsize");
              this.$store.commit("getTabsize", "mini");
            } else {
              this.$notify.error({
                title: "error",
                message: res.msg,
                showClose: false,
                duration: 2000,
              });
              this.isloading = false;
              this.loginNum = 0; // 未登录
            }
          });
        } else {
          this.loginNum = 0;
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.box {
  position: relative;
  width: 100%;
  height: 100%;
  /* background: url('../assets/img/login-bg.jpg'); */
  background-size: 100%;
}

/deep/.el-input__inner {
  height: 40px;
}

.login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  /* overflow: hidden; */
}

.title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.demo-ruleForm {
  width: 70%;
  margin: 0 auto;
  padding: 30px 20px;
}

.demo-ruleForm/deep/.el-form-item__content {
  margin-left: 0 !important;
}

.demo-ruleForm/deep/.el-button {
  width: 100%;
}

.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: skyblue;
  z-index: -9;
}

@keyframes square {
  0% {
    opacity: 0.5;
    transform: translateY(0px) rotate(45deg);
  }

  25% {
    opacity: 0.75;
    transform: translateY(-400px) rotate(90deg);
  }

  50% {
    opacity: 1;
    transform: translateY(-600px) rotate(135deg);
  }

  100% {
    opacity: 0;
    transform: translateY(-1000px) rotate(180deg);
  }
}

li {
  position: absolute;
  bottom: -160px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  list-style: none;
  animation: square 15s infinite;
  transition-timing-function: linear;
}

.bg-bubbles li:nth-child(1),
.bg-bubbles li:nth-child(20) {
  left: 10%;
}

.bg-bubbles li:nth-child(2),
.bg-bubbles li:nth-child(19) {
  left: 20%;
  width: 90px;
  height: 90px;
  animation-delay: 2s;
  animation-duration: 7s;
}

.bg-bubbles li:nth-child(3),
.bg-bubbles li:nth-child(18) {
  left: 25%;
  animation-delay: 4s;
}

.bg-bubbles li:nth-child(4),
.bg-bubbles li:nth-child(17) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-duration: 8s;
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-bubbles li:nth-child(5),
.bg-bubbles li:nth-child(16) {
  left: 70%;
}

.bg-bubbles li:nth-child(6),
.bg-bubbles li:nth-child(15) {
  left: 80%;
  width: 120px;
  height: 120px;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-bubbles li:nth-child(7),
.bg-bubbles li:nth-child(14) {
  left: 32%;
  width: 160px;
  height: 160px;
  animation-delay: 2s;
}

.bg-bubbles li:nth-child(8),
.bg-bubbles li:nth-child(13) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  animation-duration: 15s;
}

.bg-bubbles li:nth-child(9),
.bg-bubbles li:nth-child(12) {
  left: 25%;
  width: 10px;
  height: 10px;
  animation-delay: 2s;
  animation-duration: 12s;
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-bubbles li:nth-child(10),
.bg-bubbles li:nth-child(11) {
  left: 85%;
  width: 160px;
  height: 160px;
  animation-delay: 5s;
}
</style>